import { common, fonts, routerLink } from '@local/web-design-system';
import { Theme } from '@mui/material';
import { commonStyles } from 'src/styles';

export const styles = (theme: Theme) =>
    ({
        root: {
            maxWidth: '450px',
            minHeight: '200px',
            position: 'relative',
            marginTop: '-1px',
        },
        title: {
            ...fonts.widget2Heading,
            color: 'white',
        },
        titleDark: {
            ...fonts.widget2Heading,
            backgroundColor: theme.palette.grey[800],
        },
        header: {
            backgroundColor: theme.palette.primary.main,
        },
        headerDark: {
            backgroundColor: theme.palette.grey[800],
        },
        toolbar: {
            backgroundColor: theme.palette.grey[50],
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        iconText: {
            ...commonStyles(theme).buttonIconText,
            textTransform: 'none',
        },
        noTextTransform: {
            textTransform: 'none',
        },
        content: {
            ...common(theme).thinScrollBar,
            maxHeight: `calc(100vh - ${theme.spacing(37.75)})`,
            overflowX: 'hidden', // Suppress scrollbar due to padding
        },
        linkText: {
            ...routerLink(theme).linkText,
        },
        preHeader: {
            padding: '0px',
        },
        emptyStateBody: {
            maxWidth: '90%',
            flexBasis: '90%',
        },
        button: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        disabledToggleContainer: {
            margin: `${theme.spacing(2)} auto 0px auto`,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.grey[100],
            border: theme.palette.grey[200],
        },
        disabledLabel: {
            ...fonts.body2,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 'bold',
            lineHeight: `${theme.spacing(3)}`,
            marginRight: theme.spacing(2),
        },
        hidePreferences: {
            visibility: 'hidden',
        },
        justifyLeft: {
            justifyContent: 'left',
        },
        tableWrapper: {
            tableLayout: 'fixed', // Define col widths to avoid Y overflow
            maxWidth: theme.spacing(54), // Confine table to tray maxWidth-padding
        },
    }) as any;
